import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { lazyWithRetry } from '../utils/lazyWithRetry';

// Lazy-loaded components
const SignIn = lazyWithRetry(() => import("../pages/SignInPage"));
const SignUp = lazyWithRetry(() => import("../pages/SignUpPage"));
const ForgotPassword = lazyWithRetry(() => import("../pages/ForgotPassword"));
const ForgotPasswordSuccess = lazyWithRetry(() => import("../pages/ForgotPasswordSuccess"));
const ResetPassword = lazyWithRetry(() => import("../pages/ResetPassword"));
const ResetPasswordSuccess = lazyWithRetry(() => import("../pages/ResetPasswordSuccess"));
const MultiFactorAuth = lazyWithRetry(() => import("../pages/MultiFactorAuth"));
const NotFound = lazyWithRetry(() => import("../pages/NotFound"));

interface AppRoutesProps {
    error?: any;
}

const AppRoutes: React.FC<AppRoutesProps> = ({ error }) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/" element={<SignIn error={error} />} />
                <Route path="/signin" element={<SignIn error={error} />} />
                <Route path="/signup" element={<SignUp error={error} />} />
                <Route path="/forgotpassword" element={<ForgotPassword error={error} />} />
                <Route path="/resetpassword" element={<ResetPassword error={error} />} />
                <Route path="/forgetPasswordSuccess" element={<ForgotPasswordSuccess />} />
                <Route path="/resetPasswordSuccess" element={<ResetPasswordSuccess />} />
                <Route path="/secure/otp-auth" element={<MultiFactorAuth error={error} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}

export default AppRoutes;
